import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { ICustomButtonProps } from './models';
import './Button.scss';

const Button: FC<ICustomButtonProps> = ({
  ariaLabel,
  children,
  className,
  fileName,
  variant,
  onClick,
  link,
  linkToPDF,
  isDownload,
  isDropdown,
  isOpenPopUp,
  isGame,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleShowDropdown = () => setShowDropdown((prev) => !prev);
  const classes = classNames('custom-button', className, {
    [`custom-button--white`]: isDownload === '1' || isDropdown === '1' || isOpenPopUp === '1',
    [`custom-button--${variant}`]: variant,
  });
  const dropDownButtonClasses = classNames(
    'custom-button__dropdown--hide custom-button__dropdown',
    className,
    {
      [`custom-button__dropdown--show`]: showDropdown,
    }
  );

  if (isDownload && isDownload === '1') {
    return (
      <a
        href={linkToPDF?.gatsbyDocFile.relativePath}
        download={fileName}
        aria-label={ariaLabel}
        className={classes}
      >
        {children}
      </a>
    );
  }

  if (isDropdown && isDropdown === '1') {
    return (
      <div className="custom-button__dropdown__wrapper">
        <button
          onClick={handleShowDropdown}
          type="button"
          className={classes}
          aria-label={ariaLabel}
        >
          {children}
        </button>
        <div className={dropDownButtonClasses}>
          {link?.map(({ name, url }) => (
            <Link
              key={name}
              className="custom-button custom-button--white custom-button__dropdown__item"
              to={url}
            >
              {name}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  if (isOpenPopUp && isOpenPopUp === '1') {
    return (
      <button type="button" className={classes} aria-label={ariaLabel} onClick={onClick}>
        {children}
      </button>
    );
  }

  if (isGame && isGame === '1') {
    return (
      <button type="button" className={classes} aria-label={ariaLabel} onClick={onClick}>
        {children}
      </button>
    );
  }
  if (link) {
    const isInternal = /^\/(?!\/)/.test(link[0].url);

    return (
      <>
        {isInternal ? (
          <Link className="custom-button custom-button__dropdown__item" to={link[0].url}>
            {children}
          </Link>
        ) : (
          <a
            className={classes}
            href={link[0].url}
            rel="noreferrer"
            {...(link[0].target && { target: link[0].target })}
          >
            {children}
          </a>
        )}
      </>
    );
  }

  return (
    <button type="button" className={classes} aria-label={ariaLabel} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
