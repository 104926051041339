import UmbracoImage from 'components/common/Image/UmbracoImage';
import React, { FC, useState } from 'react';
import './WigglesBanner.scss';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { Modal } from 'react-bootstrap';
import YouTube from 'react-youtube';
import Button from 'components/common/Button';

const WigglesBanner: FC = ({ banner }) => {
  const {
    properties: { bannerImage, bannerImageMobile, videoId, videoButtonText, videoModalCloseButtonText, anchorLinks, heading, backButtonText },
  } = banner;

  const { windowWidth } = useScreenRecognition();
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className='wiggles-banner'>
      <div className="wiggles-banner__image-holder">
        {
          windowWidth > 1023 ?
            <UmbracoImage image={bannerImage[0].properties.image} alt={bannerImage[0].altText} /> :
            <UmbracoImage image={bannerImageMobile[0].properties.image} alt={bannerImageMobile[0].altText} />
        }
      </div>
      <a className='wiggles-banner__back-button' href="/" >
        <div className="icon-back button-back"></div>
        {backButtonText}
      </a>
      <strong className="dt-logo wiggles-banner__logo">
        <a href="/">
          <img width="100%" height="100%" src="/images/Dettol_new_logo.svg" alt="Dettol AU" />
        </a>
      </strong>
      <div className="wiggles-banner__content">
        <h1 className="wiggles-banner__heading">{heading}</h1>
        <a className='wiggles-banner__cta' onClick={handleOpenModal} role='button'>
          <span>{videoButtonText}</span>
          <span>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.8733 9.57568C29.5397 8.336 28.5623 7.35883 27.3229 7.025C25.0583 6.40527 16.0001 6.40527 16.0001 6.40527C16.0001 6.40527 6.94214 6.40527 4.6776 7.00138C3.46198 7.33498 2.46074 8.33622 2.12714 9.57568C1.53125 11.84 1.53125 16.536 1.53125 16.536C1.53125 16.536 1.53125 21.2556 2.12714 23.4963C2.46096 24.7358 3.43813 25.7132 4.67782 26.047C6.96599 26.6668 16.0003 26.6668 16.0003 26.6668C16.0003 26.6668 25.0583 26.6668 27.3229 26.0707C28.5625 25.7371 29.5397 24.7597 29.8735 23.5202C30.4694 21.2556 30.4694 16.5599 30.4694 16.5599C30.4694 16.5599 30.4933 11.84 29.8733 9.57568Z" fill="#FF0000" />
              <path d="M13.1162 20.8744L20.6486 16.5361L13.1162 12.1977V20.8744Z" fill="white" />
            </svg>
          </span>
        </a>
        <div className="wiggles-banner__links-container">
          <div className="wiggles-banner__links">
            {anchorLinks.map(({ properties: { link: [{ url, name }] } }, index) => (
              <a className='wiggles-banner__link' href={url} key={index}>{name}</a>
            ))}
          </div>
        </div>
      </div>
      <Modal
        dialogClassName="dt-media-info-box__media-modal"
        size="xl"
        show={showModal}
        onHide={handleCloseModal}
        centered>
        <Modal.Body>
          <div className="dt-video-responsive-frame">
            <YouTube id="banner-video" videoId={videoId} opts={{ playerVars:{autoplay:1} }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button ariaLabel={"close"} onClick={handleCloseModal} variant="download">
            {videoModalCloseButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WigglesBanner;
