import UmbracoImage from 'components/common/Image/UmbracoImage';
import React, { FC } from 'react';
import './WigglesRecommendedProducts.scss';
import Carousel from 'react-multi-carousel';

const WigglesRecommendedProducts: FC = (
  { recommendedProductsSection, recommendedProducts },
) => {
  const { properties: { titleRegular, titleBold, buttonText }, } = recommendedProductsSection;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  };

  return <div className="wiggles-recommended-products">
    <h2 className="wiggles-recommended-products__title">{titleRegular} <strong>{titleBold}</strong></h2>
    <Carousel responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      autoPlay={false}
      autoPlaySpeed={1000}
      containerClass="wiggles-carousel-container">
    {recommendedProducts.nodes.map((product, index) => {
      return <div key={index} className="wiggles-recommended-products__product">
        <UmbracoImage
          image={product.localHeroImage.childImageSharp}
          alt={product.name}
          className="wiggles-recommended-products__product-image"
        />
        <h3 className='wiggles-recommended-products__product-name'>{product.name}</h3>
        <a className='wiggles-recommended-products__product-link' href={product.link}>{buttonText}</a>
      </div>
    })}
    </Carousel>
  </div>
};
export default WigglesRecommendedProducts;
