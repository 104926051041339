import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import React, { FC, useState } from 'react';
import './WigglesSteps.scss';
import useScreenRecognition from 'hooks/useScreenRecognition';
import YouTube from 'react-youtube';

const WigglesSteps: FC = (
  { stepsSection },
) => {
  const {
    properties: { titleRegular, titleBold, content, image, subTitleBold, subTitleRegular, videoId, steps },
  } = stepsSection;

  const { windowWidth } = useScreenRecognition();

  const [hoveredIndices, setHoveredIndices] = useState([]);

  const handleMouseEnter = index => {
    if (!hoveredIndices.includes(index)) {
      setHoveredIndices(prevIndices => [...prevIndices, index]);
    }
  };

  return <div className="wiggles-steps">
    <h2 className="wiggles-steps__heading">{titleRegular}<br /><strong>{titleBold}</strong></h2>
    <div className='wiggles-steps__content'>
      <DangerouslySetInnerHtml html={content} />
    </div>
    <div className="wiggles-steps__logo-image">
      <UmbracoImage image={image[0].properties.image} alt={image[0].altText} />
    </div>
    <h3 className='wiggles-steps__subtitle'>{subTitleBold}</h3>
    <div className='wiggles-steps__subtitle-text'>{subTitleRegular}</div>
    <div className="wiggles-steps__video-image">
      <YouTube
        id="steps-video"
        videoId={videoId}
        opts={{
          width: windowWidth > 1023 ? 1023 : 341,
          height: windowWidth > 1023 ? 576 : 192,
          playerVars:{autoplay:0} }
          } />
    </div>
    <div className='wiggles-steps__steps' id="steps">
      {steps.map(({ properties: { title, description,
        imageMobile: [{ properties: { altText: stepAltTextMobile, image: stepImageMobile } }],
        image: [{ properties: { altText: stepAltText, image: stepImage } }],
        flipsideImage: [{ properties: { altText: flipsideAltText, image: flipsideImage } }]
      } }, index) => (

        windowWidth > 1023 ?
          <div className={`wiggles-steps__step index${index} ${hoveredIndices.includes(index) ? 'hovered' : ''}`}
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
          >
            <div className="wiggles-steps__step-front-card">
              <div className="wiggles-steps__step-image">
                <img src={stepImage.url} alt={stepAltText} />
              </div>
              <h4 className='wiggles-steps__step-title'>{title}</h4>
            </div>
            <div className="wiggles-steps__step-rear-card">
              <h4 className='wiggles-steps__step-title'>{title}</h4>
              <div className="wiggles-steps__step-description">
                <DangerouslySetInnerHtml html={description} />
              </div>
              <div className="wiggles-steps__step-image">
                <img src={flipsideImage.url} alt={flipsideAltText} />
              </div>
            </div>
          </div> :
          <div className="wiggles-steps__step" key={index}>
            <h4 className='wiggles-steps__step-title'>{title}</h4>
            <div className="wiggles-steps__step-description">
              <DangerouslySetInnerHtml html={description} />
            </div>
            <div className="wiggles-steps__step-image">
              <img src={stepImageMobile.url} alt={stepAltTextMobile} />
            </div>
          </div>
      ))}
    </div>
  </div>
};
export default WigglesSteps;
