import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import React, { FC } from 'react';
import './WigglesHabits.scss';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import useScreenRecognition from 'hooks/useScreenRecognition';


const WigglesHabits: FC = (
  { habitsSection },
) => {
  const { properties: { titleRegular, titleBold, content, habits }, } = habitsSection;

  const { windowWidth } = useScreenRecognition();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 992 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1
    }
  };

  return <div className="wiggles-habits" id="habits">
    <h2 className='wiggles-habits__heading'>{titleRegular}<br /> <strong>{titleBold}</strong></h2>
    <div className="wiggles-habits__content">
      <DangerouslySetInnerHtml html={content} />
    </div>
    <Carousel responsive={responsive}
      ssr={true}
      autoPlay={false}
      autoPlaySpeed={1000}
      containerClass="wiggles-carousel-container">
      {
        habits.map((habit, index) => {
          const { properties: { title, content, imageMobile, image } } = habit;
          return <div key={index} className="wiggles-habit">
            <div className="wiggles-habit__image">
              {windowWidth > 991 ?
                <img src={image[0].properties.image.url} alt={image[0].properties.altText} /> :
                <img src={imageMobile[0].properties.image.url} alt={imageMobile[0].properties.altText} />
              }
            </div>
            <h3 className='wiggles-habit__title'>{title}</h3>
            <div className='wiggles-habit__content'>
              <DangerouslySetInnerHtml html={content} />
            </div>
          </div>

        })}
    </Carousel>
  </div>
};
export default WigglesHabits;
