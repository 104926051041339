import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import React, { FC } from 'react';
import './WigglesPartnership.scss';
import useScreenRecognition from 'hooks/useScreenRecognition';

const WigglesPartnership: FC = ({ partnershipSection }) => {
  const { properties: { titleRegular, titleBold, subTitle, partnershipItems } } = partnershipSection;

  const { windowWidth } = useScreenRecognition();

  return <div className="wiggles-partnership" id="partnership">
    <h2 className='wiggles-partnership__heading'>{titleRegular}<br /><strong>{titleBold}</strong></h2>
    <h3 className="wiggles-partnership__subtitle">{subTitle}</h3>
    <div className='wiggles-partnership__items'>
      {partnershipItems.map(({ properties: { title, description,
        imageMobile: [{ properties: { altText: stepAltTextMobile, image: stepImageMobile } }],
        image: [{ properties: { altText: stepAltText, image: stepImage } }]
      } }, index) => (
        <div className="wiggles-partnership__item" key={index}>
          <div className="wiggles-partnership__item-image">
            {windowWidth > 767 ?
              <img src={stepImage.url} alt={stepAltText} /> :
              <img src={stepImageMobile.url} alt={stepAltTextMobile} />
            }
          </div>
          <h4 className='wiggles-partnership__item-title'>{title}</h4>
          <div className="wiggles-partnership__item-description">
            <DangerouslySetInnerHtml html={description} />
          </div>
        </div>
      ))}
    </div>
  </div>
};
export default WigglesPartnership;
