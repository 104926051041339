import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import React, { FC } from 'react';
import './WigglesReferences.scss';

const WigglesReferences: FC = (
  { referencesSection },
) => {
  const {properties: { title, content },} = referencesSection;


  return <div className="wiggles-references">
    <h2 className='wiggles-references__title'>{title}</h2>
    <div className="wiggles-references__content">
      <DangerouslySetInnerHtml html={content} />
    </div>
  </div>
};
export default WigglesReferences;
