import UmbracoImage from 'components/common/Image/UmbracoImage';
import React, { FC } from 'react';
import './WigglesGallery.scss';
import Carousel from 'react-multi-carousel';

const WigglesGallery: FC = (
  { gallerySection },
) => {
  const {properties: { titleRegular, titleBold, galleryImages },} = gallerySection;

  const responsive = {
    mobile: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    }
  };

  return <div className="wiggles-gallery">
    <h2 className="wiggles-gallery__heading">{titleRegular}<br/><strong>{titleBold}</strong></h2>
    <Carousel responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={false}
      autoPlaySpeed={1000}
      containerClass="wiggles-gallery-carousel-container">
    {
      galleryImages.map((galleryImage, index) => {
        return <div key={index} className="wiggles-gallery__image">
          <UmbracoImage image={galleryImage.properties.image} alt={galleryImage.properties.altText}/>
        </div>
      })
    }
    </Carousel>
  </div>
};
export default WigglesGallery;
