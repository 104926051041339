import React from 'react';
import { graphql } from 'gatsby';
import 'styles/main.scss';
import './WigglesPage.scss';
import Seo from 'components/common/Seo';
import WigglesBanner from '../../components/WigglesComponents/WigglesBanner';
import WigglesSteps from '../../components/WigglesComponents/WigglesSteps';
import WigglesHabits from '../../components/WigglesComponents/WigglesHabits';
import WigglesGallery from '../../components/WigglesComponents/WigglesGallery';
import WigglesPartnership from '../../components/WigglesComponents/WigglesPartnership';
import WigglesFacts from '../../components/WigglesComponents/WigglesFacts';
import WigglesRecommendedProducts from '../../components/WigglesComponents/WigglesRecommendedProducts';
import WigglesReferences from '../../components/WigglesComponents/WigglesReferences';
import Footer from 'components/Footer';
import ScrollToTop from '../../components/WigglesComponents/ScrollToTop';

const WigglesPage = ({
  data: {
    wigglesPage: {
      seoMetaKeywords,
      seoMetaDescription,
      seoNoIndex,
      seoMetaTitle,
      seoCanonicalUrl,
      seoExternalHreflangs,
      seoImage,
      ogPageType,
      link,
      banner,
      stepsSection,
      habitsSection,
      gallerySection,
      partnershipSection,
      factsSection,
      recommendedProductsSection,
      referencesSection,
    },
    brandSettings,
    siteSettings,
    footerNavigation,
    homepageSettings,
    regionDropDown,
    recommendedProducts,
  },
}) => {

  return (
    <div>
      <div className="wiggles-page">
        <Seo
          siteSettings={siteSettings}
          brandSettings={brandSettings}
          seoNoIndex={seoNoIndex}
          title={seoMetaTitle}
          description={seoMetaDescription}
          keywords={seoMetaKeywords}
          canonicalUrl={seoCanonicalUrl}
          externalHreflangs={seoExternalHreflangs}
          ogImage={seoImage}
          url={link}
          ogPageType={ogPageType}
        />
        <WigglesBanner banner={banner[0]} />
        <WigglesSteps stepsSection={stepsSection[0]} />
        <WigglesHabits habitsSection={habitsSection[0]} />
        <WigglesFacts factsSection={factsSection[0]} />
        <WigglesGallery gallerySection={gallerySection[0]} />
        <WigglesPartnership partnershipSection={partnershipSection[0]} />
        <WigglesRecommendedProducts recommendedProductsSection={recommendedProductsSection[0]} recommendedProducts={recommendedProducts} />
        <WigglesReferences referencesSection={referencesSection[0]} />
        <ScrollToTop />
      </div>
      <Footer
        regionDropDown={regionDropDown}
        footerNavigation={footerNavigation}
        brandSettings={brandSettings}
        newsletterBanner={homepageSettings?.newsletterBanner}
        siteSettings={siteSettings}
      />
    </div>
  );
};

export const query = graphql`
  query ($lang: String, $link: String!, $recommendedProducts: [String]) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    wigglesPage(link: { eq: $link }, lang: { eq: $lang }) {
      seoMetaKeywords
      seoMetaDescription
      seoNoIndex
      seoMetaTitle
      seoCanonicalUrl
      seoImage
      link
      banner{
        properties {
          heading
          bannerImage {
            properties {
              altText
              image {
                fallbackUrl
                fluid {
                  base64
                  srcSet
                  originalImg
                }
              }
            }
          }
          bannerImageMobile {
            properties {
              altText
              image {
                fallbackUrl
                fluid {
                  base64
                  srcSet
                  originalImg
                }
              }
            }
          }

          videoId
          videoButtonText
          videoModalCloseButtonText

          anchorLinks{
            properties {
              link {
                name
                url
              }
            }
          }
          backButtonText
        }
      }

      stepsSection {
        properties {
        titleRegular
        titleBold
        content
        image {
          properties {
            altText
            image {
              fallbackUrl
              fluid {
                base64
                srcSet
                originalImg
              }
            }
          }
        }
        subTitleBold
        subTitleRegular
        videoPreviewImage {
          properties {
            altText
            image {
              fallbackUrl
              fluid {
                base64
                srcSet
                originalImg
              }
            }
          }
        }
        videoId

        steps {
          structure
          properties {
            title
            description
            imageMobile {
              properties {
                altText
                image {
                  url
                }
              }
            }

            image {
              properties {
                altText
                image {
                  url
                }
              }
            }
            flipsideImage {
              properties {
                altText
                image {
                  url
                }
              }
            }
          }
        }
        }
      }

      habitsSection {
        structure
        properties {
          titleRegular
          titleBold
          content

          habits {
            structure
            properties {
              title
              content
              imageMobile {
                properties {
                  altText
                  image {
                    url
                  }
                }
              }
              image {
                properties {
                  altText
                  image {
                    url
                  }
                }
              }

            }
          }
        }
      }

      factsSection {
        structure
        properties {
          titleRegular
          titleBold
          content

          facts {
            structure
            properties {
              title
              description
              image {
                properties {
                  altText
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }

      gallerySection{
        structure
        properties {
          titleRegular
          titleBold

          galleryImages {
            structure
            properties {
              altText
              image {
                fallbackUrl
                fluid {
                  base64
                  srcSet
                  originalImg
                }
              }
            }
          }
        }
      }

      partnershipSection {
        structure
        properties {
          titleRegular
          titleBold
          subTitle
          partnershipItems {
            structure
            properties {
              title
              description
              imageMobile {
                properties {
                  altText
                  image {
                    url
                  }
                }
              }
              image {
                properties {
                  altText
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }

      recommendedProductsSection {
        structure
        properties {
          titleRegular
          titleBold
          productLinks {
            url
          }
          buttonText
        }
      }

      referencesSection {
        structure
        properties {
          title
          content
        }
      }
    }

    recommendedProducts: allUmbracoProduct(
      limit: 2
      filter: { link: { in: $recommendedProducts }, lang: { eq: $lang } }
    ) {
      nodes {
        id
        name
        link
        productColor
        recognisableFeature
        shortNameBold
        shortNameRegular
        localHeroImage {
          childImageSharp {
            fluid(maxWidth: 120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cardImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        descriptionShort
        titleBrandbank
      }
    }


    brandSettings {
      brandName
      brandLogo {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      brandLogoSchema
      youtubeUrl
      facebookUrl
    }

    regionDropDown {
      id
      selectedCountryCode
      section {
        properties {
          title
          countries {
            properties {
              code
              name
              linkURL {
                url
                name
              }
            }
          }
        }
      }
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }

    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
      newsletterBanner {
        buttonText
        buttonURL {
          url
        }
        titleText
        regularTitle
      }
    }
  }
`;

export default WigglesPage;
