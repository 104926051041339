import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import React, { FC } from 'react';
import './WigglesFacts.scss';
import Carousel from 'react-multi-carousel';

const WigglesFacts: FC = (
  { factsSection },
) => {
  const { properties: { titleRegular, titleBold, content, facts }, } = factsSection;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1220 },
      items: 5
    },
    mobile: {
      breakpoint: { max: 1219, min: 0 },
      items: 1
    }
  };

  return <div className="wiggles-facts" id="facts">
    <h2 className='wiggles-facts__heading'>{titleRegular}<br /><strong>{titleBold}</strong></h2>
    <div className="wiggles-facts__content">
      <DangerouslySetInnerHtml html={content} />
    </div>
    <Carousel responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={false}
      autoPlaySpeed={1000}
      containerClass="wiggles-carousel-container">
      {
        facts.map((fact, index) => {
          const { properties: { title, description, image } } = fact;
          return <div key={index} className="wiggles-fact">
            <div className="wiggles-fact__image">
              <img src={image[0].properties.image.url} alt={image[0].properties.altText} />
            </div>
            <h3 className='wiggles-fact__title'>{title}</h3>
            <div className="wiggles-fact__content">
              <DangerouslySetInnerHtml html={description} />
            </div>
          </div>

        })}
    </Carousel>
  </div>
};
export default WigglesFacts;
